// extracted by mini-css-extract-plugin
export var gridContainer = "index-module--gridContainer--+VONd";
export var layoutContainer = "index-module--layoutContainer--Wy89y";
export var link = "index-module--link--aN3Qk";
export var backgroundImg = "index-module--backgroundImg--caqGg";
export var header_1 = "index-module--header_1--J7Dze";
export var subHeading = "index-module--subHeading--yFwWO";
export var nav = "index-module--nav--74mnm";
export var ul = "index-module--ul--Iggi5";
export var li = "index-module--li--uJitp";
export var a = "index-module--a--b0K7H";
export var footer = "index-module--footer--2fbUX";
export var hamburgerMenu = "index-module--hamburgerMenu--BBCLG";
export var h_link = "index-module--h_link--5urpY";
export var aboutOutline = "index-module--aboutOutline--oet8c";
export var projectsOutline = "index-module--projectsOutline--UE8SM";
export var contactOutline = "index-module--contactOutline--+96PU";