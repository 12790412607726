import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Seo from "../components/seo"
import * as indexStyles from "../styles/modules/index.module.scss"
import Menu from "../components/hamburgerMenu"

const IndexPage = () => {
  return (
    <>
      <Seo title="Home" />
      <StaticImage
        className={indexStyles.backgroundImg}
        src="../images/background.jpg"
      />
      <header>
        {" "}
        {/* nav for home page is different from other pages */}
        <nav className={indexStyles.nav} id="nav">
          <ul className={indexStyles.ul}>
            <li className={indexStyles.li}>
              <Link to="/about" className={indexStyles.a}>
                About
              </Link>
            </li>
            <li className={indexStyles.li}>
              <Link to="/projects" className={indexStyles.a}>
                Projects
              </Link>
            </li>
            <li className={indexStyles.li}>
              <Link to="/contact" className={indexStyles.a}>
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <Menu hamburgerIconStyle={indexStyles.hamburgerMenu} />
      </header>

      <h1 className={indexStyles.header_1}>
        Rob Moose{" "}
        <span className={indexStyles.subHeading}>
          string arrangements, recording and live performance
        </span>
      </h1>

      <footer className={indexStyles.footer}>
        {/* Couldn't use Layout Component only in index */}
        <p>Copyright © {new Date().getFullYear()}</p>
        <p>
          Designed and developed by{" "}
          <a
            className={indexStyles.h_link}
            href="https://github.com/h15200"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hideaki Aomori
          </a>
        </p>
        <p>
          Photography by
          <a
            className="max_wanger_link link"
            href="https://www.maxwangerprintshop.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Max Wanger
          </a>
        </p>
      </footer>
    </>
  )
}

export default IndexPage
